import React from 'react'
import "./client.css"
import Marquee from 'react-fast-marquee'

const Client = () => {
  return (
    <div className='container mx-auto mt-14 md:mt-20 lg:mt-24'>
        <div className='client__title px-5 text-center'><span>Client’s we work with</span></div>
        {/* <Marquee className='mt-10 md:mt-16'>
            <div className='client__img mr-10'>
                <img src='/images/Grace.png' alt='img' />
            </div>
            <div className='client__img mr-10'>
                <img src='/images/Grace.jpg' alt='img' />
            </div>
            <div className='client__img mr-10'>
                <img src='/images/Grace.jpg' alt='img' />
            </div>
            <div className='client__img mr-10'>
                <img src='/images/Grace.jpg' alt='img' />
            </div>
            <div className='client__img mr-10'>
                <img src='/images/Grace.jpg' alt='img' />
            </div>
            <div className='client__img mr-10'>
                <img src='/images/Grace.jpg' alt='img' />
            </div>
            <div className='client__img mr-10'>
                <img src='/images/Grace.jpg' alt='img' />
            </div>
            <div className='client__img mr-10'>
                <img src='/images/Grace.jpg' alt='img' />
            </div>
        </Marquee> */}

        <div className='flex justify-center items-center flex-wrap gap-10 mt-16 mb-5'>
            <div className='client__img mr-10'>
                <img src='/images/Grace.png' alt='img' />
            </div>
            <div className='client__img mr-10'>
                <img src='/images/logo.png' alt='img' />
            </div>
            <div className='client__img mr-10'>
                <img src='/images/websitelogo.jpg' alt='img' />
            </div>
        </div>
    </div>
  )
}

export default Client