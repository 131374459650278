import React from 'react'
import "./footer.css"

// icon
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaRegEnvelope } from "react-icons/fa6";




const Footer = () => {
  return (
    <div className='footer pt-10 lg:pt-14 pl-5 lg:pl-0'>
        <div>
        <div className='container mx-auto flex flex-wrap xl:flex-nowrap justify-between xl:justify-center gap-x-10 lg:gap-x-3 xl:gap-x-10'>

            <div className='footer__content'>
                <div className='footer__title mb-4 lg:mb-7'>
                    <span>Contact Information</span>
                </div>
                <div className='footer__para my-3'>
                   <a href=''> <span>7, Perambur Redhills Road, Retteri Flyover, <br></br>Kolathur, Chennai - 99</span></a>
                </div>
                <div className='footer__para'>
                   <a href='tel:9789985622'><span>+91 9789985622</span></a> 
                </div>
                <div className='footer__para my-3'>
                   <a href=''><span>Connect@brandletdigi.com</span></a> 
                </div>
                <div className='footer__social flex gap-X-10 mt-3'>
                  <a href='https://twitter.com/Brandlet_Digi' target='blank'>  <div className='footer__socialIcon'>
                      <FaXTwitter className='__socialIconLink'/>
                    </div> </a>
                    <a href='https://www.facebook.com/brandlet.digi' target='blank'>   <div className='footer__socialIcon'>
                      <FaFacebookSquare className='__socialIconLink' />  
                    </div> </a>
                    <a href='https://www.instagram.com/brandlet_digi/' target='blank'>   <div className='footer__socialIcon'>
                      <FaInstagramSquare className='__socialIconLink' />
                    </div> </a>
                    <a href='https://mail.google.com/' target='blank'>   <div className='footer__socialIcon'>
                      <FaRegEnvelope  className='__socialIconLink' />
                    </div> </a>
                </div>
            </div>

            <div className=' mt-8 md:mt-0 footer__containerLink'>
                <div className='footer__title mb-4 lg:mb-7'>
                    <span>Services</span>
                </div>
                <div className='footer__link'>
                    <p>Digital Marketing</p>
                    <p className='my-4'>Branding</p>
                    <p>Web Design</p>
                    <p className='my-4'>SEO</p>
                </div> 
           </div>

           <div className='mt-5 md:mt-0 footer__containerLink'>
                <div className='footer__title mb-4 lg:mb-7'>
                    <span>Quick Links</span>
                </div>
                <div className='footer__link'>
                    <p>Pricing </p>
                    <p className='my-4'>Blogs</p>
                    <p>Case Studies</p>
                    <p className='my-4'>Donate</p>
                </div> 
           </div>

           <div className='mt-5 lg:mt-0 w-full sm:w-1/2 md:w-2/5 xl:w-1/4'>
                <div className='footer__title mb-4 lg:mb-7'>
                    <span>Stay Update Here</span>
                </div>
                <div className='footer__para mb-9'>
                    <span>Keep up with Brandlet's weekly offerings of new AI tools and online events</span>
                </div>
                <from>
                    <div className='footer__from'>
                        <input type='email' placeholder='Enter Your Email' />
                        <button>Subscribe</button>
                    </div>
                </from>
           </div>
           </div >
             <div className='footer__copyRights text-center pt-14 pb-8'>
                <span>Copyright © { new Date().getFullYear() } Brandlet. All rights reserved.</span>
             </div>
        </div>
    </div>
  )
}

export default Footer