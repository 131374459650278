import React, { useState } from 'react'
import "./popup.css"
import { IoClose } from "react-icons/io5";


const Popup = ({ isOpen, onToggleForm }) => {

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        number: ""
    })

    const [formError, setError] = useState({
        name: "",
        email: "",
        number: ""
    })

    const handleChangeInput = (e) =>{
        const {name, value} = e.target
        setFormData({ ...formData, [name] : value})
        setError({...formError, [name] : ""})
    }

    const validateForm = () => {

        let formValid = true
        const newError = {}

        if(!formData.name){
           formValid = false;
           newError.name = "Name is required"
        }

        if(!formData.email){
            formValid = false;
            newError.email = "Name is required"
        }

        if(!formData.number){
            formValid = false;
            newError.number = "Name is required"
        }

        setError(newError)
        return formValid
    }

    const handleClose = () =>{
        setFormData({
            name: "",
            email: "",
            number: ""
        })
        setError({
            name: "",
            email: "",
            number: ""
        })

        onToggleForm()
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if(validateForm()){
            setFormData({
                name: "",
                email: "",
                number: ""
            })
            setError({
                name: "",
                email: "",
                number: ""
            })
        }
        
    }


  return (
    <>
    { isOpen && (
    <div className='popup__container'>
        <div className='popup__content flex justify-between'>
            <div className='popup__Rightimg lg:w-1/2 hidden lg:inline-block'>
                <img src='/images/signupform.jpg' alt='img' />
            </div>
            <div className='popup__left w-full lg:w-1/2 p-3'>
                <div className='popupClose' onClick={handleClose}>
                   <IoClose />
                </div>
               
                <form className='popup__Formcontainer' onSubmit={handleSubmit}>
                    <div className='popup__title mb-4 sm:mb-5'>
                        <span>Let’s level up your brand, together</span>
                    </div>
                    <div className='popup__input'> 
                        <label>Name</label> 
                        <input type='text' placeholder='Your name' name='name' value={formData.name} onChange={handleChangeInput} />
                    </div>
                    {formError.name && (<div className="error-msg">{formError.name}</div>)}                    
                   <div className='popup__input mt-3'>
                        <label>Email</label>
                        <input type='text' placeholder='you@company.com' name='email' value={formData.email} onChange={handleChangeInput}/>
                    </div>
                    {formError.email && (<div className="error-msg">{formError.email}</div>)}                    

                    <div className='popup__input mt-3'>
                        <label>Phone number</label>
                        <input type='text' placeholder='Your number' name='number' value={formData.number} onChange={handleChangeInput}/>
                    </div>
                    {formError.number && (<div className="error-msg">{formError.number}</div>)}                    
                    <div className='popup__input mt-3'>
                        <label>How can we help?</label>
                        <textarea placeholder='Tell us a little about the project...' cols={80} rows={40}></textarea>
                    </div>
                    
                    <label className='my-3 hidden lg:inline-block'>Services</label>

                    <div className='popup__input lg:flex justify-between hidden '>
                        <div>
                            <div className='popup__checkBox flex items-center justify-center'>
                               <input  type="checkbox" />
                               <label>Website design</label>
                            </div>
                            <div className='popup__checkBox flex items-center my-2'>
                               <input type='checkbox' />
                               <label>UX design</label>
                            </div>
                            <div className='popup__checkBox flex items-center'>
                               <input type='checkbox' />
                               <label>User research</label>
                            </div>
                        </div>
                        <div className=''>
                            <div className='popup__checkBox flex items-center'>
                               <input type='checkbox' />
                               <label>Content creation</label>
                            </div>
                            <div className='popup__checkBox flex items-center my-2'>
                               <input type='checkbox' />
                               <label>Strategy & consulting</label>
                            </div>
                            <div className='popup__checkBox flex items-center'>
                               <input type='checkbox' />
                               <label>Other</label>
                            </div>
                        </div>
                    </div>
                    <div className='popupBtn mt-5'>
                        <button type='submit'>Send message</button>
                    </div>
                </form>
            </div>
        </div>
    </div> )}
    </>
  )
}

export default Popup