import React, { useEffect, useState } from 'react'
import "./main.css"
import { useTypewriter, Cursor } from "react-simple-typewriter"
import { IoLogoWechat } from "react-icons/io5";



// icon
import { GoArrowUpRight } from "react-icons/go";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Popup from '../popup/popup';





const Main = () => {

    const [isFormVisible, setFormVisible] = useState(null);

    useEffect(() => {

        const timer = setTimeout(() => {
          setFormVisible(true);
        }, 7000);
    
        return () => clearTimeout(timer);
      }, []);
    
      const toggleForm = () => {
          setFormVisible(!isFormVisible);
    };

  
  return (

    <div className='container mx-auto flex flex-wrap lg:flex-nowrap main__brandlet pt-20 pb-10 sm:pt-40 md:pt-56 md:pb-32 px-5 sm:px-0'>
      
            
      {/* Word Top */}

        <div className='w-full'>
            <div className='main__title sm:flex items-center w-full'>
                <div className='inline-block main-title__word'>
                  <span>BRANDLET</span>
                </div>


                <div className='word__containerr w-1/2 ml-3 lg:ml-8'>
                    <div className='word__text'>CREATIVE</div>
                    <div className='word__text'>DIGITAL</div>
                    <div className='word__text'>MARKETING</div>
                    <div className='word__text'>WEB DESIGN</div>
                    <div className='word__text'>MEDIA</div>
                    <div className='word__text'>STRATEGY</div>
                </div>    
            </div>

            <div className='main__subTitle sm:flex flex-wrap md:flex-nowrap items-center md:mt-10 2xl:mt-0'>

                <div className='word__containerrs w-1/2 sm:mt-3'>
                    <div className='word__textt'>AGENCY</div>
                    <div className='word__textt'>HOUSE</div>
                    <div className='word__textt'>STUDIO</div>
                    <div className='word__textt'>HUB</div>
                    <div className='word__textt'>HOUSE</div>
                    <div className='word__textt'>STUDIO</div>
                </div>   

                <div className='w-full md:w-10/12 xl:pr-32'>
                    <span className='main__para inline-block pr-36'>Helping brands connect better by smart design, clever strategy, and new tech. We solve problems with creativity, team up with clients, and make work that grabs attention and touches hearts.</span>
                    <div className='visit__main mt-3 xl:block flex items-center'>
                        <span className='visit-main__title inline-block mr-5 mb-2 xl:mr-0'>Visit us</span>
                        <div className='flex items-center gap-x-3'>
                        <a href='https://www.instagram.com/brandlet_digi/' target='blank'>   <div className='visit__icon'>
                               <FaInstagram />
                            </div> </a>
                            <a href='https://www.facebook.com/brandlet.digi' target='blank'>   <div className='visit__icon'>
                               <FaFacebookF />
                            </div> </a>
                            <a href='https://twitter.com/Brandlet_Digi' target='blank'>   <div className='visit__icon'>
                               <FaXTwitter />
                            </div> </a>
                        </div>
                    </div>
                </div>
            </div>

            <Popup isOpen={isFormVisible} onToggleForm={toggleForm} />

            {isFormVisible === false && (
               <div id="icon" onClick={toggleForm}>
                 <div className="chant-icon">
                    <IoLogoWechat />
                 </div>
               </div>
            )}


        </div>
       
    </div>
  )
}

export default Main